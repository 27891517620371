import * as React from 'react'
import Layout from '../components/layout'
import Header from '../components/header'
import Container from '../components/container'

const PageHeader = <Header title="Impressum" />

const ImprintPage = ({ location }) => {
    return (
        <Layout translatedPageLinks={[{ code: 'de', link: '/de/impressum/' }, { code: 'en', link: '/de/impressum/' }]}  pageLang="de" pageTitle="Impressum" pageHeader={PageHeader} location={location}>
            <Container>
                <section>
                    <h2>GE-T GmbH</h2>
                    <p>
                        Marie-Curie-Str. 6<br />
                        85055 Ingolstadt
                    </p>
                    <p>
                        <strong>Telefon:</strong> + 49 (0) 841 14907699<br />
                        <strong>E-Mail:</strong> <a href="mailto:info@ge-t.de">inf<span style={{ display: 'none' }}>sp0m</span>o@ge-t.de</a>
                    </p>
                    <p>
                        <strong>Geschäftsführer:</strong><br />
                        Dipl.-Oec. Marc Wille<br />
                        Christian Rühe
                    </p>
                    <p>
                        <strong>Prokura:</strong><br />
                        Jan-Klaas Witte<br />
                        Matthias Arndt
                    </p>
                    <p>
                        <strong>Registergericht:</strong> Amtsgericht Ingolstadt<br />
                        <strong>Registernummer:</strong> HRB 6434<br />
                        <strong>Umsatzsteuer-Identifikationsnummer:</strong> DE 284267884
                    </p>
                    <p>
                        {/* eslint-disable-next-line */}
                        Konzept, Gestaltung und Umsetzung von der Marketing Services Creative Group (<a href="https://www.mscg.de/" target="_blank">MSCG</a>)
                    </p>
                </section>
            </Container>
        </Layout>
    )
}

export default ImprintPage